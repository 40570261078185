import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Text } from '../ui'
import { LayoutSection } from '../layout'
import { EventDuration } from '../events'
import { PostListCompact } from '../posts'

const WidgetTodaysEvents = () => {
  const { wp: data } = useStaticQuery(graphql`
    query WidgetTodaysEventsQuery {
      wp {
        todaysEvents: events(
          first: 5
          where: {
            scope: TODAY
            status: PUBLISH
            language: DEFAULT
            orderby: {
              order: ASC
              field: START_DATE
            }
          }
        ) {
          nodes {
            ...EventFields
          }
        }
      }
    }
  `)

  const events = data.todaysEvents.nodes

  return (
    <LayoutSection heading="Ma van!">
      {
        events?.length > 0
          ? (
            <PostListCompact
              posts={(
                events.map((event) => ({
                  ...event,
                  meta: (
                    <EventDuration
                      {...event}
                      isAllDay={event.allDay}
                    />
                  ),
                }))
              )}
            />
          ) : <Text>Nincsenek mai programok.</Text>
      }
    </LayoutSection>
  )
}
export default WidgetTodaysEvents
