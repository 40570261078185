import React from 'react'
import clsx from 'classnames'
import styled from '@emotion/styled'
import getDate from 'date-fns/getDate'
import isToday from 'date-fns/isToday'
import isWeekend from 'date-fns/isWeekend'
import { Flex, Box, Text } from '../ui'

const StyledText = styled(Flex)`
  width: 30px;
  height: 30px;
  position: relative;

  &.weekend {
    opacity: .75;
  }

  &.today {
    opacity: 1;
    font-weight: ${({ theme }) => theme.fontWeights.bold};
  }

  &.outside {
    display: none;
  }

  &.eventful {
    opacity: 1;
    background: ${({ theme }) => theme.colors.accent[50]};
    color: ${({ theme }) => theme.colors.background.body};
    border-radius: ${({ theme }) => theme.radii.circle}px;

    &:hover {
      opacity: .8;
    }
  }
`

const StyledCounter = styled(Box)`
  position: absolute;
  top: -4px;
  right: -8px;
  min-width: 18px;
  font-size: 10px;
  border-radius: ${({ theme }) => theme.radii.circle}px;
  border: 2px solid ${({ theme }) => theme.colors.background.body};
`

const EventCalendarDay = ({ date, eventCount = 0, isOutsideMonth = false }) => (
  <StyledText
    alignItems="center"
    justifyContent="center"
    className={clsx({
      today: isToday(date),
      weekend: isWeekend(date),
      eventful: eventCount > 0 && !isOutsideMonth,
      outside: isOutsideMonth,
    })}
  >
    <Text fontSize={1}>
      {getDate(date)}
    </Text>
    {
      eventCount > 0
        ? (
          <StyledCounter
            bg="primary.50"
            textAlign="center"
          >
            {eventCount}
          </StyledCounter>
        ) : null
    }
  </StyledText>
)

export default EventCalendarDay
