import React from 'react'
import { Text } from '../ui'
import { LayoutSection } from '../layout'
import { PostListCompact } from '../posts'

const WidgetLatestPosts = ({ posts = [] }) => (
  <LayoutSection heading="Ne maradj le róla">
    {
      posts?.length > 0
        ? <PostListCompact posts={posts} />
        : <Text>Nincsenek friss cikkek.</Text>
    }
  </LayoutSection>
)

export default WidgetLatestPosts
